import axios from "axios";
import Fuse from "fuse.js";
import { ActionTree, GetterTree, MutationTree, createStore } from "vuex";
import idirect_module from "./store_idirect";
import newtec_module from "./store_newtec";
import sigma_module from "./store_sigma";
import tickets_module from "./store_tickets";
import preferences_module from "./store_preferences";
import change_requests_module from "./store_change_requests";
import starlink_module from "./store_starlink";
import admin_module from "./store_admin";
import cno_vno_module from "./store_cno_vno";
import i18n from "../i18n.js";
import * as api from "../services/api";
import { getObject, setObject } from "./store_helpers.js";
import { Notify } from "quasar";
import availability_module from "./store_availability";
import { Company, Link, ModemNames, ModemStatus, Service, rootState, UserRoles } from "../types/store/state";
import { CloudWatchRumFn } from "../cloudWatchRum";
import { COMPANIES } from "../constants";
import { openSupportGuidePDF } from "../services/functions";

const USER_ICON = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktcGVyc29uLWNpcmNsZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMTEgNmEzIDMgMCAxIDEtNiAwIDMgMyAwIDAgMSA2IDB6Ii8+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCA4YTggOCAwIDEgMSAxNiAwQTggOCAwIDAgMSAwIDh6bTgtN2E3IDcgMCAwIDAtNS40NjggMTEuMzdDMy4yNDIgMTEuMjI2IDQuODA1IDEwIDggMTBzNC43NTcgMS4yMjUgNS40NjggMi4zN0E3IDcgMCAwIDAgOCAxeiIvPgo8L3N2Zz4=`;
const SESSIONS_STORAGE_KEY_STARLINK_SPLASH_TEXT = "modalStarlinkManagementInfo";

const cloudWatchRum = (window.cwr ??
  ((...args) => {
    console.warn("cloudWatchRum fallback used", args);
  })) as CloudWatchRumFn;

const updateStatus = (state: rootState) => {
  // don't run if it's already running or there aren't any modem ids
  if (
    !state.statusUpdating &&
    state.company?.modems &&
    (state.company.modems.iDirect.length > 0 ||
      state.company.modems.Newtec.length > 0 ||
      state.company.modems.Starlink.length > 0 ||
      state.company.modems.OneWeb.length > 0)
  ) {
    state.statusUpdating = true;
    var modems = JSON.parse(JSON.stringify(state.company.modems));
    if (
      (modems.Newtec && modems.Newtec.length > 0) ||
      (modems.Starlink && modems.Starlink.length > 0) ||
      (modems.OneWeb && modems.OneWeb.length > 0)
    ) {
      let newtec = [];
      let starlink = [];
      let uhp = [];
      let oneweb = [];
      if (state?.company?.services.length > 0) {
        for (let s of state.company.services) {
          if (s.modems && s.modems.length > 0) {
            for (let m of s.modems) {
              if (m.type == "Newtec") {
                newtec.push(m.id);
              } else if (m.type == "Starlink") {
                starlink.push(m.serial_number);
              } else if (m.type == "UHP Networks") {
                uhp.push(m.name);
              } else if (m.type == "Intellian") {
                oneweb.push(m.id);
              }
            }
          }
        }
      }
      modems.Newtec = newtec;
      modems.Starlink = starlink;
      modems.UHP = uhp;
      modems.OneWeb = oneweb;
    }
    api
      .post("ifapi/modemsstatus", modems)
      .then((data) => {
        if (state.company?.services && state.company.services.length > 0) {
          if (data.data) {
            // index by ID (DID or name)
            let byDID: { [key: string]: ModemStatus } = {};
            let byNewtec: { [key: string]: ModemStatus } = {};
            let byStarlink: { [key: string]: ModemStatus } = {};
            let byOneWeb: { [key: string]: ModemStatus } = {};
            if (data.data.iDirect) {
              for (var m of data.data.iDirect) {
                byDID[m.DID] = m;
              }
            }
            if (data.data.Newtec) {
              for (var m of data.data.Newtec) {
                byNewtec[m.modem_id] = m;
              }
            }
            if (data.data.Starlink) {
              for (var m of data.data.Starlink) {
                byStarlink[m.id] = m;
              }
            }
            if (data.data.OneWeb) {
              for (var m of data.data.OneWeb) {
                byOneWeb[m.userTerminalId] = m;
              }
            }
            // loop modems and set status
            for (const [i, s] of state.company.services.entries()) {
              if (s.modems && s.modems.length > 0) {
                for (const [j, m] of s.modems.entries()) {
                  if (m.type == "Newtec") {
                    if (byNewtec[m.id]) {
                      state.company.services[i].modems[j].status = byNewtec[m.id];
                    }
                  }
                  if (m.type == "iDirect") {
                    if (m.DID && byDID[m.DID]) {
                      state.company.services[i].modems[j].status = byDID[m.DID];
                      // capitalize only first letter
                      if (byDID[m.DID].status) {
                        state.company.services[i].modems[j].status.status =
                          state.company.services[i].modems[j].status?.status[0] +
                          state.company.services[i].modems[j].status.status.substring(1).toLowerCase();
                      }
                    }
                  }
                  if (m.type == "Starlink") {
                    if (byStarlink[m.id]) {
                      state.company.services[i].modems[j].status = byStarlink[m.id];
                    }
                  }
                  if (m.type == "Intellian" || m.type == "Kymeta") {
                    if (byOneWeb[m.id]) {
                      state.company.services[i].modems[j].status = byOneWeb[m.id];
                    }
                  }
                }
              }
            }
          }
        }
        state.statusUpdating = false;
      })
      .catch((e) => {
        console.log("error loading modemsstatus", e);
        state.statusUpdating = false;
      });
  }
};

export const state: rootState = {
  accessToken: null,
  username: null,
  user: {
    sys_id: null,
    first_name: null,
    last_name: null,
    email: null,
    avatar: USER_ICON,
    company_sys_id: null,
  },
  userRoles: [],
  rolesFetched: false,
  speedcastUser: false,
  compassSession: null,
  loggingIn: false,
  loginRedirectUrl: null,
  loginError: null,
  showMFA: false,
  compass_session: null,
  MFAverifying: false,
  MFAerror: null,
  loggedIn: false,
  companies: [],
  company: undefined,
  companyError: false,
  statusUpdating: false,
  statusInterval: 0,
  companiesLoading: false,
  companyLoading: true,
  searchCompany: "",
  links: [
    { name: i18n.global.t("mapView"), menuMatch: "Home", url: { name: "Home" }, enabled: true },
    { name: i18n.global.t("performance"), menuMatch: "Performance", url: { name: "Performance" }, enabled: true },
    {
      name: i18n.global.t("reports"),
      menuMatch: "Reports",
      //needs to be initialized as empty because UI will display two times this
      //children is been populated in updateCompanyServices() line 944
      children: [],
      enabled: true,
    },
    {
      name: i18n.global.t("support"),
      menuMatch: "Support",
      children: [{ name: i18n.global.t("supportGuide"), click: openSupportGuidePDF, pdf: true }],
      enabled: true,
    },
  ],
  isMenuOpen: false,
  announcements: null,
  supportGuideUrl: "",
  supportGroup: "",
  isFetchingSupportGuide: false,
};

export const getters: GetterTree<rootState, rootState> = {
  accessToken(state) {
    return state.accessToken;
  },
  username(state) {
    return state.username;
  },
  userIcon(state) {
    return state.user.avatar;
  },
  user(state) {
    return state.user;
  },
  userRoles(state) {
    if (state.speedcastUser) {
      return state.userRoles.map((role) => role.name);
    }
    if (state?.company?.company_id == "all") {
      let rolesForAll: Set<string> = new Set();
      state.userRoles.forEach((role) => {
        if (role.name == "support") {
          rolesForAll.add(role.name);
        }
      });
      return Array.from(rolesForAll);
    }
    return state.userRoles
      .filter((roleSet) => roleSet.account_sys_id == state?.company?.company_id || roleSet.account_sys_id == "all")
      .map((role) => role.name);
  },
  isSpeedcast(state) {
    return /\@speedcast\.com$|(portal.user.2)/.test(state?.username ?? "");
  },
  compassSession(state) {
    return state.compassSession;
  },
  companyLoading(state) {
    return state.companyLoading;
  },
  companiesLoading(state) {
    return state.companiesLoading;
  },
  companyError(state) {
    return state.companyError;
  },
  company(state) {
    return state.company;
  },
  companies(state) {
    return state.companies;
  },
  company_name(state) {
    return state.company?.company_name;
  },
  company_id(state) {
    return state.company?.company_id;
  },
  services(state) {
    return state.company?.services;
  },
  multiCompany(state) {
    return state.companies.length > 1;
  },
  getCompanySupportGroup(state) {
    return state.supportGroup;
  },
  getUserIdentifier(state) {
    return state.user.email || state.username;
  },
  getStarlinkSplashText() {
    return "true" === sessionStorage.getItem(SESSIONS_STORAGE_KEY_STARLINK_SPLASH_TEXT);
  },
  getStarlinkStatusModems(state) {
    return state.company?.services.reduce(
      (acc, service) => {
        const starlinkModems = service.modems.filter((modem) => modem.type === "Starlink");
        if (starlinkModems.length > 0) {
          acc[service.name] = starlinkModems;
        }
        return acc;
      },
      {} as { [serviceName: string]: any[] }
    );
  },
  supportGuideUrl(state) {
    return state.supportGuideUrl;
  },
  findModem:
    (state) =>
    (srchval: string, startswith = false) => {
      if (state?.company?.services?.length ?? 0 > 0) {
        for (var s of state.company?.services || []) {
          if (s.modems && s.modems.length > 0) {
            for (var m of s.modems) {
              if (m.type == "Newtec") {
                if (m.name == srchval || (startswith && m.name.startsWith(srchval))) {
                  return m;
                }
              }
              if (m.type == "Intellian" && (m.name == srchval || m.id == srchval)) {
                return m;
              }
              if (m.type == "iDirect" && m.DID == srchval) {
                return m;
              }
              if (m.type == "Kymeta" && (m.name == srchval || m.id == srchval)) {
                return m;
              }
              if (m.type == "UHP Networks" && m.name == srchval) {
                return m;
              }
              if (m.type == "Starlink" && (m.name == srchval || m.id == srchval)) {
                return m;
              }
            }
          }
        }
      }
      return null;
    },
  modemIds: (state) => {
    var m: ModemNames = {
      iDirect: [],
      Newtec: [],
      OneWeb: [],
      UHP: [],
      Starlink: [],
    };
    if (state.company?.modems.iDirect) {
      m.iDirect = state.company.modems.iDirect;
    }
    if (state.company?.modems.UHP) {
      m.UHP = state.company.modems.UHP;
    }
    if (state.company?.modems.Newtec) {
      m.Newtec = [];
      if (state.company && state.company && state.company.services && state.company.services.length > 0) {
        for (var s of state.company.services) {
          if (s.modems && s.modems.length > 0) {
            for (var x of s.modems) {
              if (x.type == "Newtec") m.Newtec.push(x.id);
            }
          }
        }
      }
      if (state.company?.modems.Starlink) {
        m.Starlink = [];
        if (state.company && state.company && state.company.services && state.company.services.length > 0) {
          for (var s of state.company.services) {
            if (s.modems && s.modems.length > 0) {
              for (var x of s.modems) {
                if (x.type == "Starlink") m.Starlink.push(x.id);
              }
            }
          }
        }
      }
    }
    return m;
  },
  findNewtecById: (state) => (srchid: string) => {
    if (state.company && state.company && state.company.services && state.company.services.length > 0) {
      for (var s of state.company.services) {
        if (s.modems && s.modems.length > 0) {
          for (var m of s.modems) {
            if (m.type == "Newtec" && m.id == srchid) {
              return m;
            }
          }
        }
      }
    }
    return null;
  },
  findServiceByModemId: (state) => (modemId: string) => {
    return state.company?.services.find((bs) => bs.modems.find((modem) => modem.id === modemId));
  },
  findServiceNameModem:
    (state) =>
    (srchval: string, startswith = false) => {
      if (state.company && state.company && state.company.services && state.company.services.length > 0) {
        for (var s of state.company.services) {
          if (s.modems && s.modems.length > 0) {
            for (var m of s.modems) {
              if (m.type == "Newtec" && m.name == srchval) {
                return s.name;
              }
              if (startswith && m.type == "Newtec" && m.name.startsWith(srchval)) {
                return s.name;
              }
              if (m.type == "iDirect" && m.serial_number == srchval) {
                return s.name;
              }
              if (m.type == "Starlink" && m.serial_number == srchval) {
                return s.name;
              }
            }
          }
        }
      }
      return null;
    },
  findServiceFieldByName:
    (state) =>
    (srchval: string, field: string, startswith = false) => {
      if (state.company && state.company && state.company.services && state.company.services.length > 0) {
        for (var s of state.company.services) {
          if (s.name == srchval) {
            return s[field];
          }
          if (startswith && s.name.startsWith(srchval)) {
            return s[field];
          }
          if (s.modems) {
            for (var m of s.modems) {
              if (m.name == srchval) {
                return s[field];
              }
            }
          }
        }
      }
      return null;
    },
  filteredCompanies(state, getters) {
    const options = {
      includeScore: true,
      keys: ["company_name"],
      threshold: 0.2,
    };
    const query = state.searchCompany.trim();
    const companies = state.companies.filter((c) => c.company_name !== "");
    // Don't search if query is empty
    if (!query) {
      return companies.sort((a, b) => {
        if (getters.isFavorite(a)) {
          // Sort favorites alphabetically
          if (getters.isFavorite(b)) {
            return a.company_name.localeCompare(b.company_name);
          }
          return -1; // Favorites always first
        }
        return a.company_name.localeCompare(b.company_name);
      });
    }
    const fuse = new Fuse(companies, options);
    const results = fuse.search(query);
    return results.map((r) => r.item);
  },
  alerts(state) {
    var dupecheck: { [key: string]: number } = {};
    var alerts = [];
    if (state.company && state.company && state.company.services && state.company.services.length > 0) {
      for (var s of state.company.services) {
        if (s.modems && s.modems.length > 0) {
          for (var m of s.modems) {
            if (
              m.status &&
              m.status.status &&
              (m.status.status == "ALARM" || m.status.status == "Alarm" || m.status.status == "Offline")
            ) {
              if (!dupecheck[m.id]) {
                dupecheck[m.id] = 1;
                alerts.push({
                  id: m.id,
                  name: m.name,
                  DID: m.DID || null,
                  status: m.status.status,
                  time: m.status.time,
                  reason: m.status.reason || null,
                  type: m.type,
                });
              }
            }
          }
        }
      }
    }
    return alerts.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
  },
  findDevice: (state) => (deviceType: string, id: string) => {
    for (let service of state.company?.services || []) {
      for (let i = 0; i < service[deviceType]?.length || 0; i++) {
        if (service[deviceType][i].id == id) {
          return service[deviceType][i];
        }
      }
    }
  },
  isFavorite: (rootState) => (company: Company) => {
    return rootState.preferences?.favoriteCompanies?.has(company.company_id);
  },
  isFavoriteService: (rootState) => (service_sys_id: string) => {
    return rootState.preferences?.favoriteServices?.has(service_sys_id);
  },
  announcements: (state) => {
    if (state.announcements) {
      return state.announcements;
    } else {
      return null;
    }
  },
  getAnnouncement: (state) => (type: string) => {
    return state?.announcements?.find((a) => a.type === type);
  },
  isCerbosReady: (state) => {
    return state.company?.company_id && state.rolesFetched && !state.companiesLoading;
  },
};

export const actions: ActionTree<rootState, rootState> = {
  doLogin({ commit }, { loginData, router }) {
    commit("loginStart");
    console.log("Login Start");
    axios
      .post(`${import.meta.env.VITE_API_URL}/login`, loginData)
      .then(async (response) => {
        commit("loginStop", null);

        if (response.data.data == "MFA") {
          commit("updateUsername", loginData.username);
          commit("showMFA", response.data.compass_session);
        } else {
          cloudWatchRum("addSessionAttributes", { username: loginData.username });
          // redirect to Token so SSO and regular login get the same after login treatment
          router.push({
            name: "Token",
            query: {
              username: loginData.username,
              access_token: response.data.access_token,
              compass_session: response.data.compass_session,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log("in login", error);
        commit("loginStop", error?.response?.data ? error.response.data : "Login Error");
        commit("updateAccessToken", null);
      });
  },
  doMFAVerify({ commit, state }, { code, router }) {
    commit("mfaVerifyStart");
    console.log("MFA Verify Start");
    axios
      .get(`${import.meta.env.VITE_API_URL}/mfa/verifyauth/${code}/${state.compass_session}`)
      .then(async (response) => {
        if (response.data.error) {
          commit("mfaVerifyStop", response.data.error);
        } else {
          commit("mfaVerifyStop", null);
          commit("hideMFA");
          // redirect to Token so SSO and regular login get the same after login treatment
          router.push({
            name: "Token",
            query: {
              username: state.username,
              access_token: response.data.access_token,
              compass_session: response.data.compass_session,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log("in mfa", error);
        commit("mfaVerifyStop", error?.response?.data?.error ? error.response.data.error : "Authenicator Error");
        commit("updateAccessToken", null);
      });
  },
  async setAccess({ commit, dispatch }, accessdata) {
    commit("updateAccessToken", accessdata.token);
    commit("updateUsername", accessdata.username);
    commit("updateCompassSession", accessdata.compass_session);
    dispatch("loadBanner");
    await dispatch("preferences/fetchPreferences", accessdata.username);
    await dispatch("fetchUserData");
    commit("setLoginState");
  },
  gotoStartPage({ rootGetters, state }, router) {
    if (state.loginRedirectUrl != null) {
      console.log(`Redirecting to ${state.loginRedirectUrl}`);
      router.push(state.loginRedirectUrl);
      state.loginRedirectUrl = null;
    } else {
      if (
        rootGetters["preferences/startPage"] &&
        [
          "Home",
          "Performance",
          "ServicesOverview",
          "Cases",
          "ChangeRequests",
          "IPSLAsOverview",
          "SLAsOverview",
          "StarlinkReports",
        ].includes(rootGetters["preferences/startPage"])
      ) {
        console.log("Loading start page: " + rootGetters["preferences/startPage"]);
        router.push({ name: rootGetters["preferences/startPage"] });
      } else {
        router.push({ name: "Home" });
      }
    }
  },
  fetchAccessToken({ commit }) {
    commit("updateAccessToken", localStorage.getItem("accessToken"));
  },
  fetchUsername({ commit }) {
    commit("updateUsername", localStorage.getItem("username"));
    commit("updateCompassSession", localStorage.getItem("compassSession"));
  },
  setUserData({ commit, dispatch }, username) {
    api.get(`tdapi/user/${username}`).then((userData) => {
      if (userData.data) {
        commit("updateUserData", userData.data);
      }
    });
  },
  async fetchUserData({ commit, dispatch, state }) {
    var userData = getObject("userData");
    if (userData) {
      commit("updateUserData", userData);
    } else {
      dispatch("setUserData", state.username);
    }
    await dispatch("fetchRoles");
  },
  clearUserData({ commit }) {
    commit("nullUserData");
  },
  logOut({ commit, dispatch }, router) {
    commit("clearAccessToken");
    commit("clearUser");
    commit("clearCompassSession");
    commit("updateCompanies", []);
    dispatch("clearCompany");
    commit("idirect/resetModemData");
    commit("newtec/resetModemData");
    commit("nullUserData");
    commit("preferences/nullPreferences");
    commit("mfaVerifyStop", null);
    commit("hideMFA");
    dispatch("clearStarlinkSplashText");
    if (router) {
      router.push("/login");
    }
  },
  // ****COMPANIES****
  clearCompany({ commit, dispatch }) {
    commit("nullCompany");
    dispatch("tickets/clearTickets");
    dispatch("change_requests/clearChangeRequests");
  },
  setCompany({ commit, dispatch }, company = false) {
    commit("startLoadingCompany");
    const co = getObject<Company>("company");
    if (co && (!company || co.company_id == company.company_id)) {
      commit("updateCompany", co);
      dispatch("loadCompany");
    } else if (company) {
      commit("updateCompany", company);
      dispatch("loadCompany");
    }
  },
  async loadCompanies({ commit, state }) {
    if (state.statusInterval == null && state.company && state.company.services && state.company.services.length > 0) {
      // if companies is populated and status isn't updating start updating it, happens on page refresh
      commit("beginUpdatingStatus");
    }
    try {
      if (state.companies.length || state.companiesLoading) return; // Only load companies once.
      state.companiesLoading = true;
      const { data } = await api.get("tdapi/companies");
      commit("updateCompanies", data);
    } catch (e) {
      let message: string | null = null;
      if (axios.isAxiosError(e)) {
        if (e.response) {
          message = typeof e.response.data === "string" ? e.response.data : null;
        } else {
          message = e.message;
        }
      }
      Notify.create({
        message: `Could not fetch companies. ${message}`,
        type: "negative",
        actions: [{ label: "Dismiss", color: "white" }],
        position: "bottom-right",
      });
    }
    state.companiesLoading = false;
  },
  async loadCompany({ commit, state }) {
    state.companyError = false;
    try {
      if (state.company && state.company.company_id) {
        commit("startLoadingCompany");
        const { data } = await api.get("ifapi/company/" + state.company.company_id);
        if (data) {
          commit("updateCompanyServices", data);
          commit("beginUpdatingStatus");
          commit("setCompanySupportGroup");
        } else {
          commit("setCompanyError", 1002);
        }
        commit("stopLoadingCompany");
      }
    } catch (e) {
      console.log("load company error", e);
    }
  },
  fetchCompany({ commit }) {
    commit("updateCompany", getObject("company"));
  },
  // ***************
  //  preferences
  toggleMapMode({ commit, state }, mapMode) {
    commit("preferences/updateMapMode", { username: state.username, mapMode: mapMode });
  },
  toggleDarkMode({ commit, state }, darkMode) {
    commit("preferences/updateDarkMode", { username: state.username, darkMode: darkMode });
  },
  toggleDataSpeedMeasure({ commit, state }, dataSpeedMeasure) {
    commit("preferences/updateDataSpeedMeasure", { username: state.username, dataSpeedMeasure: dataSpeedMeasure });
  },
  setLanguage({ commit, state }, language) {
    commit("preferences/updateLanguage", { username: state.username, language: language });
  },
  setStartPage({ commit, state }, startPage) {
    commit("preferences/updateStartPage", { username: state.username, startPage: startPage });
  },
  toggleFavorite({ commit, getters, state }, company) {
    const isFavorite = getters.isFavorite(company);
    if (isFavorite) {
      commit("preferences/removeFavorite", { company: company, username: state.username });
    } else {
      commit("preferences/addFavorite", { company: company, username: state.username });
    }
  },
  toggleFavoriteService({ commit, getters, state }, service_sys_id) {
    const isFavorite = getters.isFavoriteService(service_sys_id);
    if (isFavorite) {
      commit("preferences/removeFavoriteService", { service_sys_id: service_sys_id, username: state.username });
    } else {
      commit("preferences/addFavoriteService", { service_sys_id: service_sys_id, username: state.username });
    }
  },
  toggleCnoVnoMode({ commit, state }, cnoVnoMode) {
    commit("preferences/updateCnoVnoMode", { username: state.username, cnoVnoMode });
  },

  // Menu
  openMenu({ commit }) {
    commit("setMenuOpen", true);
  },
  closeMenu({ commit, state }) {
    commit("setMenuOpen", false);
  },
  toggleMenu({ commit, state }) {
    commit("setMenuOpen", !state.isMenuOpen);
  },
  async loadModems({ state, getters, dispatch }) {
    const response = await api.get(`tdapi/companymodems/${state.company?.company_id}`);
    const idlist = response.data.idlist;
    if (idlist?.iDirect?.length > 0) {
      dispatch("idirect/setDIDList", response.data.idlist.iDirect);
      await dispatch("idirect/getModems", getters["idirect/DIDs"]);
    } else {
      dispatch("idirect/clearIdirectModems");
    }
    if (idlist?.Newtec?.length > 0) {
      await dispatch("newtec/setModemNamesList", idlist.Newtec);
    } else {
      dispatch("newtec/clearNewtecModems");
    }
  },
  stopUpdatingStatus({ commit }) {
    commit("endUpdatingStatus");
  },
  async fetchRoles({ commit }) {
    try {
      const { data } = await api.get("tdapi/roles");
      commit("setRoles", data);
      commit("setRolesFetched", true);
      if (
        data.includes("compass_cno_vno_basic") &&
        !state.username?.includes("@speedcast.com") &&
        state.username !== "portal.user.2"
      ) {
        commit("preferences/updateCnoVnoMode", { username: state.username, cnoVnoMode: { active: true } });
      }
    } catch (error) {
      console.log(error);
      commit("setRoles", []);
    }
  },
  async loadBanner({ commit }) {
    try {
      const response = await api.get("tdapi/announcements");
      commit("setAnnouncements", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  confirmStarlinkSplashText() {
    sessionStorage.setItem(SESSIONS_STORAGE_KEY_STARLINK_SPLASH_TEXT, "true");
  },
  clearStarlinkSplashText() {
    sessionStorage.removeItem(SESSIONS_STORAGE_KEY_STARLINK_SPLASH_TEXT);
  },
  setSupportGuideUrl({ commit }, url) {
    commit("setSupportGuideUrl", url);
  },
  setIsFetchingSupportGuide({ commit }, boolean) {
    commit("setIsFetchingSupportGuide", boolean);
  },
  async refreshCerbosPermissions({ commit, dispatch }) {
    commit("setRolesFetched", false);
    await dispatch("fetchRoles");
    commit("setRolesFetched", true);
  },
};

export const mutations: MutationTree<rootState> = {
  loginStart: (state) => (state.loggingIn = true),
  loginStop: (state, errorMessage) => {
    state.loggingIn = false;
    state.loginError = errorMessage;
  },
  showMFA: (state, compass_session) => {
    state.showMFA = true;
    state.compass_session = compass_session;
  },
  hideMFA: (state, compass_session) => {
    state.showMFA = false;
    state.compass_session = null;
  },
  mfaVerifyStart: (state) => {
    state.MFAverifying = true;
    state.MFAerror = null;
  },
  mfaVerifyStop: (state, errorMessage) => {
    state.MFAverifying = false;
    state.MFAerror = errorMessage;
  },
  updateAccessToken: (state, accessToken) => {
    if (accessToken) {
      // don't set null accessToken
      localStorage.setItem("accessToken", accessToken);
      state.accessToken = accessToken;
    }
  },
  updateUsername: (state, username) => {
    if (username) {
      // don't set null username
      localStorage.setItem("username", username);
      state.username = username;
      cloudWatchRum("addSessionAttributes", { username });
    }
  },
  updateCompassSession: (state, compassSession) => {
    if (compassSession) {
      // don't set null compassSession
      localStorage.setItem("compassSession", compassSession);
      state.compassSession = compassSession;
    }
  },
  clearUser: (state) => {
    state.username = null;
    localStorage.removeItem("username");
    state.user.avatar = USER_ICON;
  },
  clearCompassSession: (state) => {
    state.compassSession = null;
    localStorage.removeItem("compassSession");
  },
  updateUserData: (state, userData) => {
    state.user.sys_id = userData.sys_id;
    state.user.first_name = userData.first_name;
    state.user.last_name = userData.last_name;
    state.user.email = userData.email;
    state.user.company_sys_id = userData.company_sys_id;
    if (userData.avatar) {
      state.user.avatar = userData.avatar;
    } else {
      state.user.avatar = USER_ICON;
    }
    setObject("userData", state.user);
    cloudWatchRum("addSessionAttributes", { username: userData.email });
  },
  nullUserData: (state) => {
    state.user = {
      sys_id: null,
      first_name: null,
      last_name: null,
      email: null,
      avatar: USER_ICON,
      company_sys_id: null,
    };
    localStorage.removeItem("userData");
  },
  clearAccessToken: (state) => {
    localStorage.removeItem("accessToken");
    state.accessToken = null;
  },
  nullCompany: (state) => {
    state.company = undefined;
    localStorage.removeItem("company");
  },
  updateCompanies: (state, companies) => {
    state.companies = companies;
  },
  updateCompany: (state, company) => {
    state.company = company;
    setObject("company", state.company);
  },
  updateCompanyServices: (state, companyservices) => {
    const services = companyservices
      .filter((service: Service) => {
        // filter sites without device types we show
        if (
          service.acus?.length ||
          service.ipinterfaces?.length ||
          service.modems?.length ||
          service.ipslas?.length ||
          service.routers?.length ||
          service.wanaccelerators?.length ||
          service.switchs?.length
        ) {
          return true;
        }
        if (service.others?.length) {
          for (var o of service.others) {
            if (
              o.element === "Device" &&
              o.monitor === "solarwinds" &&
              (o.deviceType === "Base Transceiver Station" || o.deviceType === "Configuration Item")
            ) {
              return true;
            }
          }
        }
        return false;
      })
      .map((service: Service) => {
        if (service.modems?.length > 0) {
          service.modems = service.modems
            .filter(
              (m) =>
                (m.type == "iDirect" ||
                  m.type == "Intellian" ||
                  m.type == "Kymeta" ||
                  m.type == "Newtec" ||
                  m.type == "Starlink" ||
                  m.type == "UHP Networks") &&
                !m.name.includes("MDM6000")
            )
            .sort((a, b) => {
              if (a.type < b.type) return -1;
              if (a.type > b.type) return 1;
              return a.name.localeCompare(b.name);
            });
          const nonStarlinkModem = service.modems.some((modem) => modem.type !== "Starlink");
          if (nonStarlinkModem) {
            service.mostRecentModem = service.modems
              .filter((modem) => modem.type !== "Starlink" && modem.lat != null && modem.lon != null)
              .sort((a, b) => new Date(b.gps_time).getTime() - new Date(a.gps_time).getTime())[0];
          } else {
            service.mostRecentModem = service.modems
              .filter((modem) => modem.lat != null && modem.lon != null)
              .sort((a, b) => new Date(b.gps_time).getTime() - new Date(a.gps_time).getTime())[0];
          }
          return service;
        } else {
          return service;
        }
      });
    if (state.company) {
      state.company.services = services;
    } else {
      state.company = {
        company_id: "",
        company_name: "",
        modems: {
          iDirect: [],
          Newtec: [],
          OneWeb: [],
          Starlink: [],
          UHP: [],
        },
        service_level: "",
        services: services,
      };
    }
    // get modem id lists, use object to avoid dupes
    var newtec: { [key: string]: number } = {};
    var oneweb: { [key: string]: number } = {};
    var idirect: { [key: string]: string } = {};
    var uhp: { [key: string]: string } = {};
    var starlink: { [key: string]: string } = {};
    var hasIPSLA = false;
    for (var s of companyservices) {
      if (s.modems) {
        for (var m of s.modems) {
          if (!m.lat && !m.lon && s.lat && s.lon) {
            // add lat/lon to modem if service has it set(from SNOW) and modem doesn't
            m.lat = s.lat;
            m.lon = s.lon;
          }
          if (m.type == "Newtec") {
            newtec[m.name] = 1;
          }
          if (m.type == "iDirect") {
            if (m.DID != 0 && m.DID != undefined) {
              idirect[m.DID] = m.name;
            }
          }
          if (m.type == "Intellian" || m.type == "Kymeta") {
            oneweb[m.id] = m.name;
          }
          if (m.type == "UHP Networks") {
            uhp[m.serial_number] = m.name;
          }
          if (m.type == "Starlink") {
            starlink[m.id] = m.name;
          }
        }
      }
      if (s.ipslas && Object.keys(s.ipslas).length > 0) {
        hasIPSLA = true;
      }
    }
    state.company.modems = {
      iDirect: [...Object.keys(idirect)].sort((a, b) => idirect[a].localeCompare(idirect[b])), // sort DIDs by modem name
      Newtec: [...Object.keys(newtec)].sort((a, b) => a.localeCompare(b)),
      OneWeb: [...Object.keys(oneweb)].sort((a, b) => a.localeCompare(b)),
      UHP: [...Object.values(uhp)].sort((a, b) => a.localeCompare(b)),
      Starlink: [...Object.values(starlink)].sort((a, b) => a.localeCompare(b)),
    };
    setObject("company", state.company);
    state.links[2].children = [];
    if (Object.keys(idirect).length > 0 || Object.keys(newtec).length > 0 || Object.keys(uhp).length > 0) {
      state.links[2].children.push({ name: i18n.global.t("servicesOverview"), url: { name: "ServicesOverview" } });
    }
    if (hasIPSLA) {
      state.links[2].children.push({ name: i18n.global.t("ipslasOverview"), url: { name: "IPSLAsOverview" } });
    }
    if (Object.keys(starlink).length > 0) {
      state.links[2].children.push({ name: i18n.global.t("starlinkOverview"), url: { name: "StarlinkUsageReport" } });
    }
    if (state.company.company_id === COMPANIES.CCL) {
      //CCL
      state.links[2].children.push({ name: i18n.global.t("slasOverview"), url: { name: "SLAsOverview" } });
    }
    if (state.links[2].children.length === 0) {
      state.links[2].enabled = false;
    } else {
      state.links[2].enabled = true;
    }
  },
  startLoadingCompany: (state) => {
    state.companyLoading = true;
  },
  stopLoadingCompany: (state) => {
    state.companyLoading = false;
  },
  beginUpdatingStatus: (state) => {
    updateStatus(state);
    state.statusInterval = setInterval(() => {
      if (document.visibilityState === "visible") {
        updateStatus(state);
      }
    }, 60000);
  },
  endUpdatingStatus: (state) => {
    clearInterval(state.statusInterval);
  },
  setSearchCompany: (state, searchCompany) => {
    state.searchCompany = searchCompany;
  },
  setMenuOpen: (state, isMenuOpen) => {
    state.isMenuOpen = isMenuOpen;
  },
  setLoginState: (state) => {
    if (state.accessToken) {
      state.loggedIn = true;
    }
  },
  resetProtectedMenu: (state) => {
    //support
    if (state.links[3].children && state.links[3].children.length > 1) {
      state.links[3].children.shift();
      state.links[3].children.shift();
    }
    //Manage My Starlink
    if (state.links[4]) {
      state.links.splice(4, 1);
    }
  },
  setCompanyError: (state, code) => {
    state.companyError = code;
  },
  setManageMyStarlinkMenu: (state, link) => {
    state.links.push(link);
  },
  setSupportMenu: (state, links: Link[]) => {
    links.forEach((link) => {
      let support = state.links.find((link) => link.menuMatch == "Support");
      support?.children?.unshift(link);
    });
  },
  setRoles: (state, roles: UserRoles[]) => {
    state.speedcastUser = roles.some((role) => role.name == "speedcast_user");
    state.userRoles = roles;
  },
  setRolesFetched: (state, status) => {
    state.rolesFetched = status;
  },
  setAnnouncements: (state, announcements) => {
    if (!announcements.error) {
      for (let a of announcements) {
        a.startDate = new Date(a.startDate).toISOString();
        a.endDate = a.endDate ? new Date(a.endDate).toISOString() : "";
      }

      state.announcements = announcements;
    }
  },
  setSupportGuideUrl(state, url) {
    state.supportGuideUrl = url;
  },
  setIsFetchingSupportGuide(state, boolean) {
    state.isFetchingSupportGuide = boolean;
  },
  setCompanySupportGroup(state) {
    const supportGroups = state.company?.services.map((service) => service.support_group).filter(Boolean);

    const groupCounts = supportGroups?.reduce(
      (acc, group) => {
        acc[group] = (acc[group] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>
    );

    if (groupCounts && Object.keys(groupCounts).length > 0) {
      const mostFrequentGroup = Object.entries(groupCounts).reduce((a, b) => (a[1] > b[1] ? a : b))[0];
      if (state.supportGroup !== mostFrequentGroup) {
        state.supportGroup = mostFrequentGroup;
        state.supportGuideUrl = "";
      }
    }
  },
};

export default createStore({
  state,
  getters,
  actions,
  mutations,
  modules: {
    idirect: idirect_module,
    newtec: newtec_module,
    sigma: sigma_module,
    tickets: tickets_module,
    change_requests: change_requests_module,
    preferences: preferences_module,
    starlink: starlink_module,
    admin: admin_module,
    availability: availability_module,
    cno_vno: cno_vno_module,
  },
});
