import { get, post, Delete } from "../services/api";
import { STARLINK_ALLOWED_COUNTRIES } from "../views/starlink/constants";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ActivatedKit,
  ActivatedStatus,
  ActivationKit,
  Kit,
  KitOperationStatus,
  OptInOut,
  PlanChangedStatus,
  PendingAction,
  Plan,
  Service,
} from "../types/store/starlink";
import { rootState } from "../types/store/state";
import { Notify } from "quasar";
import i18n from "../i18n";
import axios, { AxiosError } from "axios";

export interface StarlinkModule {
  namespaced: boolean;
  state: () => StarlinkState;
  getters: GetterTree<StarlinkState, rootState>;
  actions: ActionTree<StarlinkState, rootState>;
  mutations: MutationTree<StarlinkState>;
}

type PaginationData = {
  rowsNumber: number;
  skip: number;
  top: number;
};

export interface StarlinkState {
  activationKits: ActivationKit[];
  kits: Kit[];
  plans: Plan[];
  services: Service[];
  activatedKits: ActivatedKit[];
  pendingActions: PendingAction[];
  optInOut: OptInOut[];
  activatedStatus: ActivatedStatus[];
  paginationData: PaginationData;
  kitOperationStatus: KitOperationStatus;
  planChangedStatus: PlanChangedStatus | { [key: string]: any };
}

const starlink_module: StarlinkModule = {
  namespaced: true,
  state: () => ({
    activationKits: [],
    kits: [],
    plans: [],
    services: [],
    activatedKits: [],
    pendingActions: [],
    optInOut: [],
    activatedStatus: [],
    paginationData: {
      rowsNumber: 0,
      skip: 0,
      top: 0,
    },
    kitOperationStatus: {},
    planChangedStatus: {},
  }),
  getters: {
    activationKits: (state) => {
      return state.activationKits || [];
    },
    kits: (state) => {
      return state.kits;
    },
    plans: (state) => {
      return state.plans;
    },
    services: (state) => {
      return state.services.sort((a, b) => a.name.localeCompare(b.name));
    },
    activatedKits: (state) => {
      return state.activatedKits;
    },
    pendingActions: (state) => {
      return state.pendingActions;
    },
    optInOut: (state) => {
      return state.optInOut;
    },
    paginationData: (state) => {
      return state.paginationData;
    },
    kitOperationStatus: (state) => {
      return state.kitOperationStatus;
    },
    planChangedStatus: (state) => {
      return state.planChangedStatus;
    },
  },
  actions: {
    setActivationKits({ commit }, activKits) {
      return new Promise((resolve, reject) => {
        commit("updateActivationKits", activKits);
        resolve(null);
      });
    },
    getAvailableKits({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        get(`itapi/${rootGetters["company_id"]}/starlink/kits?status=available`)
          .then(({ data }) => {
            const availableKits = data.kits
              .filter((kit: Kit) => {
                return STARLINK_ALLOWED_COUNTRIES.includes(kit.provisioningAccountCode);
              })
              .map((kit: Kit) => {
                const match = /\(([^)]+)\)/.exec(kit.provisioningAccountCode);
                if (match && match[1]) {
                  kit.countryCode = match[1];
                }
                return kit;
              });

            commit("updateKits", availableKits);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getPlans({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        get(`itapi/${rootGetters["company_id"]}/starlink/priceplans`)
          .then(({ data }) => {
            commit("updatePlans", data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getServices({ commit, rootGetters }) {
      return get(`itapi/${rootGetters["company_id"]}/starlink/services`)
        .then(({ data }) => {
          commit("updateServices", data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getActivatedKits({ commit, rootGetters }, params) {
      if (params.cachedResponse) {
        return commit("updateActivatedKits", params.cachedResponse);
      }
      const { includeRecentSubscriptionHistory, top, skip, kitId } = params;
      return new Promise((resolve, reject) => {
        get(`itapi/${rootGetters["company_id"]}/starlink/kits`, {
          params: { includeRecentSubscriptionHistory, top, skip, kitId },
        })
          .then(({ data }) => {
            commit("updateActivatedKits", data);
            commit("updateServicesPagination", {
              includeRecentSubscriptionHistory,
              top,
              skip,
              rowsNumber: data.count,
              foundKitPage: data.foundKitPage,
            });
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setActivatedKits({ commit }, payload: { id: string; priority: boolean }) {
      return new Promise((resolve, reject) => {
        post("itapi/starlink/editserviceplans", payload)
          .then(() => {
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    async updateKit({ commit, rootGetters }, kit: { kitId: string; kitName: string }) {
      const { kitId, kitName } = kit;
      try {
        commit("setKitUpdating", { kitId, isUpdating: true });
        await post(`itapi/${rootGetters["company_id"]}/starlink/kits/${kitId}/name`, { kitName });
        commit("updateKitName", { kitId, kitName });
      } catch (error) {
        console.error("Error updating kit name:", error);
        throw error;
      } finally {
        commit("setKitUpdating", { kitId, isUpdating: false });
      }
    },
    optInOutStatus({ commit }, payload) {
      const bodyOptinoutstatus = payload.map(
        ({
          kitId,
          serviceline,
          provisioningAccountCode,
        }: {
          kitId: string;
          serviceline: string;
          provisioningAccountCode: string;
        }) => ({
          id: kitId,
          serviceline,
          provisioningAccountCode,
        })
      );

      if (payload.cachedResponse) {
        return commit("updateOptInOut", payload.cachedResponse);
      }
      return new Promise((resolve, reject) => {
        // cSpell:words optinoutstatus
        post("itapi/starlink/optinoutstatus", bodyOptinoutstatus)
          .then((response) => {
            commit("updateOptInOut", response);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    async pollTaskStatus({ commit, dispatch }, payload) {
      try {
        const { data } = await get(`itapi/task/${payload.taskId}/status`);
        const status = data.status;
        if (status === "Completed") {
          commit("updateKitOperationStatus", {
            status: status,
            kitId: payload.kitId,
            finalStatus: payload.request,
          });
          commit("updateActualKits", { kitId: payload.kitId, status: payload.request });
          Notify.create({
            message: i18n.global.t("starlink.updateMsg", { kitId: payload.kitId }),
            type: "positive",
            actions: [{ label: "Dismiss", color: "black" }],
            position: "bottom-right",
          });
        } else if (status === "Confirmed") {
          setTimeout(() => {
            dispatch("pollTaskStatus", payload);
          }, 3000);
        } else {
          console.error("Error check task", status);
          commit("updateKitOperationStatus", {
            status: status,
            kitId: payload.kitId,
            finalStatus: payload.request,
          });
          Notify.create({
            message: i18n.global.t("starlink.errorChangeKitStatus", { kitId: payload.kitId }),
            type: "negative",
            actions: [{ label: "Dismiss", color: "white" }],
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.error("Error retrieving status");
      }
    },
    async deactivate({ commit, dispatch, rootGetters }, { kitId }: { kitId: string }) {
      try {
        commit("updateKitOperationStatus", { status: "Confirmed", kitId: kitId, finalStatus: "Deactivated" });
        const { data: task } = await post(`itapi/${rootGetters["company_id"]}/starlink/kits/${kitId}/deactivate`);
        dispatch("pollTaskStatus", { ...task, request: "Deactivated" });
      } catch (error) {
        console.error("Error deactivating kits");
        commit("deleteKitOperationStatus", { kitId: kitId });
        Notify.create({
          message: i18n.global.t("starlink.errorDeactivateKit", { kitId }),
          type: "negative",
          actions: [{ label: "Dismiss", color: "white" }],
          position: "bottom-right",
        });
      }
    },
    async suspend({ commit, dispatch, rootGetters }, { kitId }: { kitId: string }) {
      try {
        commit("updateKitOperationStatus", { status: "Confirmed", kitId: kitId, finalStatus: "Suspended" });
        const { data: task } = await post(`itapi/${rootGetters["company_id"]}/starlink/kits/${kitId}/suspend`);
        dispatch("pollTaskStatus", { ...task, request: "Suspended" });
      } catch (error) {
        console.error("Error suspending kits");
        commit("deleteKitOperationStatus", { kitId: kitId });
        Notify.create({
          message: i18n.global.t("starlink.errorSuspendKit", { kitId }),
          type: "negative",
          actions: [{ label: "Dismiss", color: "white" }],
          position: "bottom-right",
        });
      }
    },
    async resume({ commit, dispatch, rootGetters }, { kitId }: { kitId: string }) {
      try {
        commit("updateKitOperationStatus", { status: "Confirmed", kitId: kitId, finalStatus: "Activated" });
        const { data: task } = await post(`itapi/${rootGetters["company_id"]}/starlink/kits/${kitId}/resume`);
        dispatch("pollTaskStatus", { ...task, request: "Activated" });
      } catch (error) {
        console.error("Error resuming kits");
        commit("deleteKitOperationStatus", { kitId: kitId });
        Notify.create({
          message: i18n.global.t("starlink.errorResumeKit", { kitId }),
          type: "negative",
          actions: [{ label: "Dismiss", color: "white" }],
          position: "bottom-right",
        });
      }
    },
    async activate({ commit, dispatch, rootGetters }, activateKitsPayload) {
      try {
        const { data } = await post(`itapi/${rootGetters["company_id"]}/starlink/activate`, activateKitsPayload);
        data.activationResponses.forEach((task: { status: string; value: ActivatedStatus }) => {
          const optIn = activateKitsPayload.find((kit: ActivatedKit) => kit.kitId == task.value.kitId).optIn;
          commit("updateKitOperationStatus", {
            status: "Confirmed",
            kitId: task.value.kitId,
            finalStatus: "Activated",
            optIn: optIn,
          });
          dispatch("pollTaskStatus", { ...task.value, request: "Activated" });
        });
      } catch (error: any) {
        if (error.response?.data?.error) {
          const errorData = error.response.data.error;
          Object.keys(errorData).forEach((key) => {
            const index = parseInt(key.replace("/", ""));
            if (!isNaN(index) && index >= 0 && index < activateKitsPayload.length) {
              const kitId = activateKitsPayload[index].kitId;
              const optIn = activateKitsPayload[index].optIn;
              commit("updateKitOperationStatus", {
                status: "Failed",
                kitId: kitId,
                finalStatus: "Activated",
                optIn: optIn,
              });
              Notify.create({
                message: i18n.global.t("starlink.errorActivateKit", { kitId }),
                type: "negative",
                actions: [{ label: "Dismiss", color: "white" }],
                position: "bottom-right",
              });
            }
          });
        }
        error.response.data.activationResponses.forEach(
          (task: { status: string; value?: ActivatedStatus; reason?: { kitId: string } }) => {
            if (task.status === "fulfilled" && task.value) {
              const optIn = activateKitsPayload.find((kit: ActivatedKit) => kit.kitId == task.value?.kitId).optIn;
              commit("updateKitOperationStatus", {
                status: "Confirmed",
                kitId: task.value.kitId,
                finalStatus: "Activated",
                optIn: optIn,
              });
              dispatch("pollTaskStatus", { ...task.value, request: "Activated" });
            } else {
              Notify.create({
                message: i18n.global.t("starlink.errorActivateKit", { kitId: task.reason?.kitId }),
                type: "negative",
                actions: [{ label: "Dismiss", color: "white" }],
                position: "bottom-right",
              });
              commit("updateKitOperationStatus", {
                status: "Failed",
                kitId: task.reason?.kitId,
                finalStatus: "Activated",
              });
            }
          }
        );
        throw error;
      }
    },

    async updateCurrentPlan(
      { commit, dispatch, rootGetters },
      plan: { kitId: string; planId: number; planName: string }
    ) {
      const { kitId, planId, planName } = plan;

      try {
        const { data } = await post(`itapi/${rootGetters["company_id"]}/starlink/kits/${kitId}/planchange`, { planId });

        const status = data?.status;

        let isScheduled = status.includes("scheduled for downgrade");

        if (status === "Completed") {
          // Completed means then the task on IBIS is finished and plan was changed
          Notify.create({
            message: i18n.global.t("popupPlanChange.success"),
            type: "positive",
            actions: [{ label: "Dismiss", color: "black" }],
            position: "bottom-right",
          });
          commit("updatePlanChangeStatus", {
            //Doesn't include blinker
            status: "Completed",
            kitId,
            taskId: data.taskId,
          });
        } else if (status === "Confirmed") {
          //Includes "updating..." blinker message
          commit("updatePlanChangeStatus", {
            status: "Confirmed",
            kitId,
            taskId: data.taskId,
          });
          setTimeout(() => {
            dispatch("updateCurrentPlan", { kitId, planId });
          }, 3000);
        } else if (isScheduled) {
          commit("updateKit", {
            kitId,
            pendingPlanChange: { planId, planName, submittedAt: "Just now" },
          });
          Notify.create({
            message: i18n.global.t("popupPlanChange.scheduled"),
            type: "positive",
            actions: [{ label: "Dismiss", color: "black" }],
            position: "bottom-right",
          });
        } else {
          Notify.create({
            message: i18n.global.t("popupPlanChange.error"),
            type: "negative",
            actions: [{ label: "Dismiss", color: "white" }],
            position: "bottom-right",
          });
        }
      } catch (e) {
        const errors = e as Error | AxiosError;
        if (!axios.isAxiosError(errors)) {
          const message = errors.message;
          throw new Error(message);
        } else {
          throw new Error(errors?.message || errors?.response?.data?.message);
        }
      }
    },
    async deletePlanChange({ commit, rootGetters }, kit) {
      try {
        const { data } = await Delete(`itapi/${rootGetters["company_id"]}/starlink/kits/${kit.kitId}/planchange`);
        if (data.status == "Completed") {
          commit("updateKit", {
            kitId: kit.kitId,
            pendingPlanChange: null,
          });
          Notify.create({
            message: i18n.global.t("popupPlanChange.deletePlanChangeRequestSuccess"),
            type: "positive",
            actions: [{ label: "Dismiss", color: "black" }],
            position: "bottom-right",
          });
        }
      } catch (e) {
        Notify.create({
          message: i18n.global.t("popupPlanChange.deletePlanChangeRequestError"),
          type: "negative",
          actions: [{ label: "Dismiss", color: "white" }],
          position: "bottom-right",
        });
      }
    },
  },
  mutations: {
    updateActivationKits: (state, data) => {
      state.activationKits = data;
    },
    updateKit: (state, data) => {
      const kitId = data?.kitId;
      const kit = state.activatedKits.findIndex((kit) => kit.kitId === kitId);

      state.activatedKits[kit] = { ...state.activatedKits[kit], ...data };
    },
    updateKits: (state, data) => {
      state.kits = data;
    },
    updatePlans: (state, data) => {
      state.plans = data;
    },
    updateServices: (state, data) => {
      state.services = data;
    },
    updateActivatedKits: (state, data) => {
      state.activatedKits = data.kits;
    },
    updateServicesPagination: (state, paginationData: PaginationData) => {
      state.paginationData = paginationData;
    },
    updatePendingActions: (state, data) => {
      state.pendingActions = data.kits;
    },
    updateOptInOut: (state, response) => {
      state.optInOut = response.data;
    },
    updateActivatedStatus: (state, response) => {
      state.activatedStatus = response.data;
    },
    updateKitOperationStatus: (state, { status, kitId, finalStatus }) => {
      state.kitOperationStatus[kitId] = { ...state.kitOperationStatus[kitId], status, finalStatus };
    },
    deleteKitOperationStatus: (state, { kitId }) => {
      delete state.kitOperationStatus[kitId];
    },
    updateActualKits: (state, { kitId, status }) => {
      const kit = state.activatedKits.findIndex((aKit) => aKit.kitId === kitId);
      if (kit >= 0) {
        state.activatedKits[kit].statusCode = status;
        state.activatedKits[kit].subscriptionStatusCode = status;
        delete state.kitOperationStatus[kitId];
      }
    },
    updatePlanChangeStatus: (state, { kitId, status, taskId }) => {
      state.planChangedStatus[kitId] = { ...state.planChangedStatus[kitId], planChangedStatus: { taskId, status } };
    },
    updateKitName: (state, { kitId, kitName }) => {
      const kitIndex = state.activatedKits.findIndex((kit) => kit.kitId === kitId);
      if (kitIndex !== -1) {
        state.activatedKits[kitIndex].name = kitName;
      }
    },
    setKitUpdating: (state, { kitId, isUpdating }) => {
      const kitIndex = state.activatedKits.findIndex((kit) => kit.kitId === kitId);
      if (kitIndex !== -1) {
        state.activatedKits[kitIndex].isUpdating = isUpdating;
      }
    },
  },
};

export default starlink_module;
