import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get, post, Delete } from "../services/api";
import { rootState } from "../types/store/state";

export interface AdminModule {
  namespaced: boolean;
  state: () => AdminState;
  getters: GetterTree<AdminState, rootState>;
  actions: ActionTree<AdminState, rootState>;
  mutations: MutationTree<AdminState>;
}

export interface AdminState {
  defaultTableHeaders: TableHeaders[];
  defaultTableHeadersAlerts: TableHeaders[];
  userCompaniesRoles: UserCompaniesRoles;
  users: User[];
  usersAlerts: User[];
  submitError: string | null;
  availableCompanies: AvailableCompanies[];
  availableRoles: AvailableRoles[];
  availableRolesCompany: AvailableRolesCompany[];
}

export interface AvailableCompanies {
  sys_id: string;
  name: string;
}

export interface AvailableRoles {
  sys_id: string;
  name: string;
}

export interface AvailableRolesCompany {
  id: string;
  role: string;
}

export interface CompanyRoles {
  account_sys_id: string;
  role_id: string;
  user_sys_id: string;
}

export interface GrantedRoles {
  account_sys_id: string;
  role: CompanyRoles;
}

export interface ContactRelationships {
  company_id: string;
  company_name: string;
  service_level: string;
}
export interface User {
  [key: string]: any;
  customerNumber: string;
  userId: string;
  emailAddress: string;
  username: string;
  sys_id: string;
  isAlertUser: boolean;
  grantedRoles: GrantedRoles[];
}

export interface UserCompaniesRoles {
  [key: string]: any;
  roles?: GrantedRoles[];
  getUsersCompany?: string;
  getUsersChildCompanies?: string[];
}

export interface NewUser {
  [key: string]: any;
  mailAddress: string;
  first_name: string;
  last_name: string;
  contactRelationships: ContactRelationships[];
  grantedRoles: GrantedRoles[];
}

export interface TableHeaders {
  id: string;
  role: string;
}

const admin_module: AdminModule = {
  namespaced: true,
  state: () => ({
    availableCompanies: [],
    availableRoles: [],
    availableRolesCompany: [],
    defaultTableHeaders: [],
    defaultTableHeadersAlerts: [],
    userCompaniesRoles: {},
    users: [],
    usersAlerts: [],
    submitError: null,
  }),
  getters: {
    availableCompanies: (state) => {
      return state.availableCompanies;
    },
    availableRoles: (state) => {
      return state.availableRoles;
    },
    availableRolesCompany: (state) => {
      return state.availableRolesCompany;
    },
    defaultTableHeaders: (state) => {
      return state.defaultTableHeaders;
    },
    defaultTableHeadersAlerts: (state) => {
      return state.defaultTableHeadersAlerts;
    },
    userCompaniesRoles: (state) => (userId: string) => {
      return state.userCompaniesRoles[userId] || null;
    },
    getUserById: (state) => (userId: string) => {
      return state.users.find((user) => user.userId === userId) || null;
    },
    users: (state) => {
      return state.users;
    },
    usersAlerts: (state) => {
      return state.usersAlerts;
    },
  },
  actions: {
    deleteUser(_, data) {
      return Delete(`tdapi/company/${data.companyId}/users/${data.userId}`)
        .then(() => {
          return Promise.resolve("Success");
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    deleteUserAll(_, data) {
      return Delete(`tdapi/users/${data.userId}`)
        .then(() => {
          return Promise.resolve("Success");
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    getAvailableRolesForCompany({ commit }, companyId) {
      return get(`tdapi/roles/companies/${companyId}`)
        .then((res) => {
          commit("updateAvailableRolesCompany", res.data);
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    getDataForNewUsers({ commit }, companyId) {
      get(`tdapi/companies/${companyId}/child-companies`)
        .then((res) => {
          commit("updateAvailableCompanies", res.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getDataForRolesTable({ commit }, companyId) {
      // return get(`tdapi/companies/${companyId}/user-permissions`)
      return get(`tdapi/roles/companies/${companyId}/users`)
        .then((res) => {
          commit("updateRolesTable", res.data.data);
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    getDataForAlertsTable({ commit }, companyId) {
      return Promise.allSettled([get(`itapi/starlink/${companyId}/alerts`), get(`itapi/starlink/alerts/all`)])
        .then((res) => {
          commit("updateAlertsTable", res);
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    getAllRolesForUser({ commit }, userId) {
      return get(`tdapi/roles/users/${userId}`)
        .then((res) => {
          commit("updateAllRolesForUser", { data: res.data, userId });
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    updateRoles({ dispatch, state }, data) {
      const { account, user, userRole = "", role = "", value } = data;
      let roleId: string | undefined = undefined;

      if (userRole) {
        roleId = userRole;
      } else {
        roleId = state.defaultTableHeaders.find((element) => element.role === role)?.id;
      }

      if (value === true) {
        return dispatch("updateAddRole", { account, user, role: roleId });
      } else {
        return dispatch("updateRemoveRole", { account, user, role: roleId });
      }
    },
    updateAddRole({ commit }, data) {
      return post("tdapi/roles/users", {
        user_sys_id: data.user.userId,
        account_sys_id: data.account,
        role_id: data.role,
      })
        .then(() => {
          commit("updateUsersRoles", {
            user_sys_id: data.user.userId,
            account_sys_id: data.account,
            role_id: data.role,
            value: true,
          });
          return Promise.resolve("Success");
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    updateRemoveRole({ commit }, data) {
      return Delete("tdapi/roles/users", {
        data: { user_sys_id: data.user.userId, account_sys_id: data.account, role_id: data.role },
      })
        .then(() => {
          commit("updateUsersRoles", {
            user_sys_id: data.user.userId,
            account_sys_id: data.account,
            role_id: data.role,
            value: false,
          });
          return Promise.resolve("Success");
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    makeAdmin({ dispatch }, data) {
      try {
        return dispatch("updateAddRole", { ...data, account: "all" });
      } catch (e) {
        throw e;
      }
    },
    removeAdmin({ dispatch }, data) {
      try {
        return dispatch("updateRemoveRole", { ...data, account: "all" });
      } catch (e) {
        throw e;
      }
    },
    createUser({ rootGetters }, payload) {
      return post(`tdapi/companies/${rootGetters["company_id"]}/users`, payload).catch((e) => {
        const message = e.response?.data.message || "Couldn't add user, please try again later";
        throw new Error(message);
      });
    },
  },
  mutations: {
    updateAllRolesForUser: (state, data) => {
      state.userCompaniesRoles[data.userId] = data.data;
    },
    updateAvailableCompanies: (state, data) => {
      state.availableCompanies = data.companies;
    },
    updateAvailableRoles: (state, data) => {
      state.availableRoles = data;
    },
    updateAvailableRolesCompany: (state, data) => {
      state.availableRolesCompany = data;
    },
    updateRolesTable: (state, data) => {
      const { roleList, employees }: { roleList: TableHeaders[]; employees: User[] } = data;
      if (typeof roleList === "string" || roleList.length === 0 || employees.length === 0) {
        throw new Error();
      }

      state.defaultTableHeaders = roleList;
      state.users = employees;
    },
    updateUsers: (state, data) => {
      for (const record of data) {
        const userNoEdited = state.users.filter((user) => user.username.includes(record.username));
        if (userNoEdited.length > 0) {
          userNoEdited[0].roles = Object.entries(record.roles)
            .filter((element) => element[1])
            .map((element) => element[0]);
        } else {
          record.roles = Object.entries(record.roles)
            .filter((element) => element[1])
            .map((element) => element[0]);
          state.users.push(record);
        }
      }
    },
    updateUsersRoles: (state, data) => {
      const user = state.users.findIndex((user) => user.sys_id === data.user_sys_id);
      if (user !== -1) {
        if (data.value) {
          state.users[user].grantedRoles.push({
            account_sys_id: data.account_sys_id,
            role: { account_sys_id: data.account_sys_id, role_id: data.role_id, user_sys_id: data.user_sys_id },
          });
        } else {
          state.users[user].grantedRoles = state.users[user].grantedRoles.filter(
            (role) => role.role.role_id !== data.role_id
          );
        }
      }
    },
  },
};

export default admin_module;
